import fetchWithHeaders from "../../fetchInterceptor";
import { BACKEND_ENDPOINTS } from "../../public/Constants/endpoints";

// Function to fetch user data using access token
// Function to update user data using access token
export const orderABoxApi = async (payload) => {
  const url = `${process.env.BACKEND_URL}box/box-option/`;
  const imageURLs = payload?.inspiration_pictures?.map(
    (picture) => picture.url
  );
  const newPayload = {
    ...payload,
    inspiration_pictures: imageURLs?.length !== 0 ? imageURLs : null,
    occasions: [payload?.occasions],
    occasion_subtext: !payload?.occasion_subtext
      ? null
      : Array.isArray(payload?.occasion_subtext)
        ? payload?.occasion_subtext
        : [payload?.occasion_subtext],
  };
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(newPayload),
      method: "POST",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });
    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error?.response?.data;
  }
};
export const orderABoxApiStepTwo = async (payload) => {
  const url = `${process.env.BACKEND_URL}box/box-option/${payload?.box_id}/`;
  delete payload.box_id;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "PUT",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error?.response?.data;
  }
};
export const PUT_CREATE_BOX = async (payload) => {
  const url = BACKEND_ENDPOINTS?.CREATE_BOX;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "PUT",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error?.response?.data;
  }
};
