import fetchWithHeaders from "../../fetchInterceptor";

export const postCreateReturnOrder = async (payload) => {
  const url = `${process.env.BACKEND_URL}box/return-order`;
  try {
    const response = await fetchWithHeaders(url, {
      body: JSON.stringify(payload),
      method: "POST",
    });
    const data = await response?.json();
    window.newrelic?.addPageAction(`${url} success`, {
      data: JSON.stringify(data),
    });

    return data;
  } catch (error) {
    window.newrelic?.noticeError(`error in ${url} api `, {
      attribute1: {
        payload: JSON.stringify(payload),
        error: JSON.stringify(error),
      },
    });
    return error?.response?.data;
  }
};
