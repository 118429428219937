import { getCookie } from "cookies-next";
import { ACCESS_TOKEN } from "./public/Constants/EnumConstants";

const fetchWithHeaders = async (url, options = {}) => {
  const token = getCookie(ACCESS_TOKEN);

  const headers = {
    ...options.headers,
    "Content-Type": "application/json",
  };

  if (token) {
    headers.Authorization = token;
  }

  const modifiedOptions = {
    ...options,
    headers,
  };

  const response = await fetch(url, modifiedOptions);

  return response;
};

export default fetchWithHeaders;
