export const BOXID_TEXT = "BoxID-";
export const NO_BOXES_TEXT = "No Boxes to Show!";
export const COMMENTS_FIELD = "rework_additional_info";
export const COMMENTS_LABEL =
  "Please tell us how we can improve to make sure we hit the mark next time";
export const NEXT_STEP_FIELD = "next_step";
export const NEXT_STEP = "next_step";
export const PICK_UP_LABEL = "When can we collect";
export const PICK_UP_FIELD = "collection_schedule";
export const SUBMIT_TEXT = "submit";
export const DESCRIPTION =
  "Here are the items that were delivered to you, by your stylist.  Let us know what worked, or didn't, and why. This helps us continue to find the perfect fits for you!";

export const ALL_RETURN_DESCRIPTION =
  "Oops! Seems like you are returning the entire Box. we are sorry for this experience. Please select an option so we can make this experience better for you in future. ";
export const TOTAL_ITEMS_TEXT = "Total Items: ";
export const OPTIONS = [
  { text: "Rework My Box", id: "rework" },
  { text: "A Styling Session at Wear That", id: "styling_session" },
  { text: "No rework needed", id: "no_rework" },
];
export const NEXT_STEP_OPTIONS = {
  rework: "rework",
  styling_session: "stylingSession",
};
export const TIME_FIELD = "timeSlot";
export const NOT_AVAILABLE_TEXT =
  "We are not available on 25th December for any services. Sorry for any inconvenience.";

export const TIME_FIELD_LABEL = "Pick a preferred collection time";
export const REQUIRED_FIELD = "This field is required";
export const STYLING_NOTE_REQUIRED = "Styling note is required";
export const DATE_FIELD_REQUIRED = "Please Select a Date";
export const TIME_SLOT_REQUIRED = "Please Select a Time Slot";

export const RESPONSES_LIST = [
  "additional_info",
  "size",
  "price",
  "style",
  "quality",
];
export const REASONS = (item) =>
  RESPONSES_LIST?.map((response) => {
    if (response === "additional_info" || response === "size_info") {
      return item?.[response] !== ""
        ? {
            reason: "additional_info",
            note: item?.[response],
          }
        : null;
    } else if (item?.[response] !== "") {
      return {
        reason: item?.[response],
      };
    } else return null;
  })?.filter((reason) => reason !== null);
